import { useEffect, useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { Backdrop } from "@mui/material";
import { BaseDirectory, mkdir, exists, readFile } from "@tauri-apps/plugin-fs";
import { appDataDir } from "@tauri-apps/api/path";
import toast from "react-hot-toast";
import { useStationAuth } from "../contexts/StationAuthContext";
import {
  useGetStationDataQuery,
  useInitializeStationMutation,
  useRefreshStationCertificateMutation,
} from "../generated/graphql";
import { invoke, isTauri } from "@tauri-apps/api/core";
import { getVersion } from "@tauri-apps/api/app";
import StationDetails from "./home/StationDetails";

type Props = {};

export default function TauriInitializer({}: Props) {
  const { showLoginForm, setShowLoginForm, isTauriApp } = useStationAuth();

  const [canClose, setCanClose] = useState(false);

  useEffect(() => {
    const delay = async (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const updateCanClose = async () => {
      if (!canClose) {
        await delay(500);
        setCanClose(true);
      }
    };
    updateCanClose();
  }, [showLoginForm]);

  const [showSecret, setShowSecret] = useState(false);
  const [secret, setSecret] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const [{ data: initializeStationData }, initializeStation] =
    useInitializeStationMutation();

  const [{ data: stationData, error: stationDataError }] =
    useGetStationDataQuery({
      pause: !showSecret || secret === "",
      variables: {
        secret: secret,
      },
    });
  const _stationData = stationData?.getStationData;

  const [{ data: refreshStationCertificateData }, refreshStationCertificate] =
    useRefreshStationCertificateMutation();

  useEffect(() => {
    if (refreshStationCertificateData) {
      console.info(
        "Refreshed station certificate",
        refreshStationCertificateData
      );
    }
  }, [refreshStationCertificateData]);

  useEffect(() => {
    const initializeDir = async () => {
      await mkdir("", { baseDir: BaseDirectory.AppData, recursive: true });
      console.log("Checking for station-cert.pem", await appDataDir());
      if (
        !(await exists("station-cert.pem", { baseDir: BaseDirectory.AppData }))
      ) {
        setShowSecret(true);
      } else {
        setShowSecret(false);
      }
    };
    initializeDir();
  }, [isTauriApp]);

  const handleGenerate = async () => {
    if (secret === "") {
      toast.error("Secret cannot be empty");
      return;
    } else setIsGenerating(true);
  };

  const _initializeStation = async () => {
    if (isTauri()) {
      try {
        // Get current time from World Time API
        const timeResponse = await fetch("https://worldtimeapi.org/api/ip");
        const timeData = await timeResponse.json();
        const timestamp = Math.floor(
          new Date(timeData.datetime).getTime() / 1000
        );

        await invoke("generate_certificate", {
          roomId: _stationData?.roomId,
          stationId: _stationData?.id,
          timestamp: timestamp,
        });

        const certificate = await readFile("station-cert.pem", {
          baseDir: BaseDirectory.AppData,
        });

        const stationAppVersion = await getVersion();

        const response = await initializeStation({
          secret: secret,
          certificate: Buffer.from(certificate).toString("base64"),
          stationAppVersion: stationAppVersion as string,
        });

        if (response.data) {
          console.log("Initialized station", response.data);
          setShowSecret(false);
        } else {
          throw new Error("Failed to initialize station");
        }
      } catch (error) {
        throw new Error("Failed to initialize station");
      }
    }
  };

  useEffect(() => {
    if (isGenerating) {
      setIsGenerating(false);
      if (!_stationData) {
        toast.error("Failed to get station data");
        return;
      }
      _initializeStation();
    }
  }, [isGenerating, _stationData]);

  if (showSecret) {
    return (
      <div
        style={{
          height: "100vh", // Full viewport height
          display: "flex",
          flexDirection: "column",
          overflow: "hidden", // Prevent scrolling
        }}
      >
        <Backdrop
          className="backdrop"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex" }}
          open={true}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <StationDetails />

            <TextField
              fullWidth
              label="Secret"
              name="secret"
              type="text"
              autoComplete="off"
              autoFocus
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  // "& fieldset": {
                  //   borderColor: "white", // This will change the border color
                  // },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white", // This will change the label color
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
                "& .MuiFilledInput-input": {
                  backgroundColor: "lightgray", // change this to your desired color
                },
              }}
            />

            <Button
              variant="contained"
              onClick={handleGenerate}
              size="large"
              fullWidth
              sx={{
                mt: 2,
                ml: 2,
              }}
            >
              Confirm
            </Button>
          </Box>
        </Backdrop>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh", // Full viewport height
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        overflow: "hidden", // Prevent scrolling
        userSelect: "none", // Prevent text selection
      }}
    >
      <Backdrop
        className="backdrop select-none"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex" }}
        open={showLoginForm}
        onClick={() => {
          if (canClose) {
            setShowLoginForm(false);
            setCanClose(false);
          }
        }}
      >
        <Box
          sx={{
            m: "auto",
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              // Centering GIF
              marginLeft: "150px",
              marginTop: "150px",
              userSelect: "none",
            }}
            height={500}
            width={500}
            src="/Tap.gif"
            alt="Loading..."
          />
        </Box>
      </Backdrop>
    </div>
  );
}
